<template>
  <div class="content">
    <a-form layout="inline">
      <a-form-item label="查询时间">
        <a-month-picker v-model="query.time" @change="customQuery"/>
      </a-form-item>
    </a-form>

    <iframe :src="src"
            style="width: 100%; height: 83vh; display: block; border: 0px;">
    </iframe>
  </div>

</template>

<script>
import {mapState} from "vuex";
import {getToken} from "@/utils/auth";
import {formatToDate, getPreviewDate} from "@/utils/time";
import moment from "moment";
import {baseURL} from '@/api/network'

export default {
  name: "month",
  data() {
    return {
      query: {
        time: undefined,
      },
      raw: baseURL+'/jmreport/view/541175175402336256',
      src: ''
    }
  },
  created() {
    this.query.time = moment()
    this.generateSrc()
  },
  methods: {
    customQuery() {
      this.src = `${this.raw}&startTime=${formatToDate(this.query.time.startOf('month'))}&endTime=${formatToDate(this.query.time.startOf('month').add(1, 'months').subtract(1, 'days'))}`
    },
    generateSrc() {
      const token = getToken()
      const moduleToken = this.$store.getters.module.moduleCode
      this.raw = `${this.raw}?token=${token}&moduleToken=${moduleToken}`
      this.customQuery()
    }
  },
  computed: mapState({
    moduleCode: state => state.user.module.moduleCode
  }),
  watch: {
    moduleCode() {
      this.generateSrc()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;
}
</style>

